import React from "react"
import styled from 'styled-components'

const DivForm = styled.div`
    margin-bottom: ${(props)=> props.spacing ? props.spacing : `16px`};
`

function FormGroup(props){
    return <DivForm {...props}  >{props.children}</DivForm>
}

export default FormGroup
