import React from 'react'
import styled from 'styled-components'

const StyledModal = styled.div`
  position: fixed;
  z-index: 1;
  left: 500px;
  top: 30%;
  ouverflow:auto;
`
const ExitBtn = styled.a`
  color: #aaa;
  float: right;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;

  &:hover, &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
`
const ExitHidden = styled.div`
  
`

const OkBtn = styled.button`

`

function Modal(props){
  return <StyledModal {...props}>
          {props.exit ? <ExitBtn href="">X</ExitBtn> : <ExitHidden></ExitHidden>}
          {props.children}
          {props.button ? <OkBtn>{props.buttonText}</OkBtn> : <ExitHidden></ExitHidden>}
        </StyledModal>
}

export default Modal
