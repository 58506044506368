import React from 'react';
import styled from 'styled-components';
import close from './close.svg'

const InputText = styled.input`
font-family: Raleway;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;

display: flex;
align-items: center;
text-decoration-line: underline;

color: #292B30;


    border: none;
    border: 1px solid #40BF91;
    box-sizing: border-box;
    border-radius: 10px;
    outline:  none;
    padding: 16px;
    width: 522px;
    height: 58px;

    background-image: url(${close});
    background-repeat: no-repeat;
    background-position: 485px 19px;
    text-indent: 24px;
    vertical-algn: middle;
    background-size: 20px;

&::placeholder {
    text-decoration: none;
}
`

const StyledContainer =styled.div`
    display: flex;
    flex-direction: column;    
`

function InputGreen(props){
    return  <StyledContainer>
                <InputText type="text" {...props} />
            </StyledContainer>
}

export default InputGreen