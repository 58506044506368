import React from 'react'
import styled from 'styled-components'

const Ttle = styled.h1`
font-family: Raleway;
font-style: normal;
font-weight: bold;
font-size: 28px;
line-height: 33px;
`

function Title(props){
  return <Ttle {...props}>{props.children}</Ttle>
}

export default Title
