import { CLICK_ADD_USUARIO, CLICK_ADD_LOGADO, FETCH_QUESTIONS, FETCH_QUESTIONS_OPTIONS, FETCH_FORMS } from '../actions/actionsTypes'

const initialState = {
    usuario: { email: '', senha: '', nome: ''},
    logado: false,
    questions: {}
}

export const clickReducer = (state = initialState, action) => {
    switch(action.type){
        case CLICK_ADD_USUARIO:
            return { ...state, usuario: action.usuario };
        case CLICK_ADD_LOGADO:
            return { ...state, logado: action.logado };
        case FETCH_QUESTIONS:
            return { ...state, questions: action.questions };
        case FETCH_QUESTIONS_OPTIONS:
            return { ...state, questionsOptions: action.questionsOptions };
        case FETCH_FORMS:
            return { ...state, forms: action.forms };
        default:
            return state;
    }
}