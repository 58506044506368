export default function getURLParams(type) {
  const params = new URLSearchParams(window ? window.location.search : {});

  let [document, key] = "";
  if (type === "bill") {
    const token = atob(params.getAll("token"));
    [document, key] = token.split(":");
  } else {
    key = params.getAll("st");
    document = params.getAll("cr");
  }

  return {
    document,
    key,
  };
}
