export const OPTIONSDAYS = {
    expirationDays() {

        let option = {value:' ', label: 'Escolha a nova data de vencimento'};
        let options = [];
        options.push(option);
        let day = 1;
        for (let i=0; i < 27; i++) {            
            option = {value: day.toString(), label: day.toString()  }
            options.push(option);
            day++;
        }
        return options;
    }
}

export default OPTIONSDAYS;