import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`

  text-align: center;
  background-color: #F1F5FA;

  width: 618px;
  height: 82px;
`

function Footer(props){
  return <StyledFooter {...props}>
          {props.children}
         </StyledFooter>
}

export default Footer
