import React from 'react'
import styled from 'styled-components'
import { Icon } from '../..'

const StyledTag = styled.div`
    background-color: #EAEAEA;
    border-radius: 20px;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 12px; 

    
    margin-right: 8px;

   

    min-width: 198px;

    margin-bottom: 12px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
const Text = styled.span`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 14px;

color: #292B30;


`

function Tags(props){
    return  <StyledTag>
                <Text>{props.label}</Text>
                <Icon type="x" />
            </StyledTag>
}

export default Tags