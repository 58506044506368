import React from 'react'
import styled from 'styled-components'
import { Grid, Icon, Links, pesquisa11, pesquisa12, pesquisa21, pesquisa22, pesquisa31, pesquisa32 } from '../../..';


const ContainerCentral = styled.div`
    border: 1px solid #ccc;
    border-radius: 12px;


    ${props=>{
        switch(props.tipo){
            case 'pink': return (`background-image: url(${pesquisa11}), url(${pesquisa12});`); break;
            case 'blue': return (`background-image: url(${pesquisa21}), url(${pesquisa22});`); break;
            case 'purple': return (`background-image: url(${pesquisa31}), url(${pesquisa32});`); break;
            default: return (`background-image: url(${pesquisa21}), url(${pesquisa22});`); break;
        }
    }}

    background-repeat: no-repeat;
    height: 148px;

    margin-bottom: ${props=>props.margin ? props.margin : "0px"};
    width: 98%;
`

const TagHeader = styled.span`
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    ${props=>{
        if(props.color !== ""){
            return `background-color: ${props.color};`
        }
    }}

    color: #FFFFFF;

    width: 96px;
    height: 24px; 

    border-radius: 6px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    
    margin-bottom: 8px;
`

const TitleHeader = styled.h1`
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    color: #FFFFFF;


`

const TitleBody = styled.h1`
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #000000;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    width: 100%;
`

const ContainerAlertBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;

`

const ContainerBody = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-right: ${props=>props.margin};
`

const TextAlertBody = styled.div`

`

function ContainerAlert(props){

    return  <ContainerCentral tipo={props.tipo} margin={props.marginBottom}>
               <Grid row>
                   <Grid column style={{
                       marginTop: '32px',
                       marginLeft: '32px',
                       marginBottom: '32px',
                       marginRight: '38px',
                       width: '350px'
                   }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start'
                        }}>
                            <TagHeader color={props.tag.cor} >{props.tag.texto}</TagHeader>
                            <TitleHeader>{props.titulo}</TitleHeader>
                            {props.linkHeader !== false && <Links 
                                    to={props.linkHeader.url}
                                    label={props.linkHeader.text}
                                    color="#fff"
                                    underline
                            />}
                        </div>
                   </Grid>
                   <Grid row style={{
                       width: '100%'
                   }}>
                        {props.bodyTitle !== '' && <TitleBody>{props.bodyTitle}</TitleBody>}
                        {props.bodyTitle === '' && <ContainerAlertBody>
                            {props.listaAlertas.map((chave, valor)=>{
                                return  <ContainerBody 
                                            margin={valor < (props.listaAlertas.length -1) ? '16px' : '0px'}
                                        >
                                            <Icon type={chave.icon} />
                                            <TextAlertBody>{chave.label}</TextAlertBody>
                                        </ContainerBody>
                            })}
                        </ContainerAlertBody>}
                   </Grid>
               </Grid>
            </ContainerCentral>
}       

export default ContainerAlert