import React from 'react'
import styled from 'styled-components'
import { Icon } from '../..'

const ContainerStyled = styled.div`
    padding: 16px;
    border: 1px ${props=>props.type ? props.type : 'solid'} #5C6066;
    border-radius: 12px;
    margin-bottom: 4px;
`
const Text = styled.p`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;

color: #5C6066;
`

function Afirmativa(props){
    return <ContainerStyled {...props}>
        <Text>
            {props.icon && <Icon type="check" />} 
            <b>{props.numero} </b>
            <span>{props.valor}</span>
        </Text>
    </ContainerStyled>
}

export default Afirmativa