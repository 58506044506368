import React from 'react'
import { Container } from '../../..'
import styled from 'styled-components'

const ContainerCentral = styled.div`
    width: 100%;

    ${props=>{
        if(props.width){
            return `width: ${props.width};`
        }
        if(props.height){
            return `height: ${props.height};`
        }
    }}
    background-color: #FFFFFF;
    padding: 32px;
    border: 1px solid #ccc;
    border-radius: 12px;

   
    position: relative;
    
`
const Tag = styled.div`
    background-color: ${props=>props.color ? props.color : '#fff'};
    width: 20px;
    height: 5px;
    border-radius: 6px;
    
    margin-bottom: 8px;
    
`

const Title = styled.h2`
font-family: Raleway;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 21px;

color: #292B30;

margin-bottom: 20px;
`

function DialogBlank(props){
    return <ContainerCentral {...props}>
                <Tag color={props.color} />  
                <Title>{props.title}</Title>  
                
                    {props.children}
            </ContainerCentral>
}

export default DialogBlank