import React from 'react'
import styled from 'styled-components'
import { Links, Icon } from '../../../style'
import Grid from '../../grid/grid'

const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: ${props=>props.spacing !== "" || props.spacing !== undefined  ? props.spacing : `row`};

  margin-right: 32px;
  vertical-align: middle;
`

function Breadcumbs(props){
  return <StyledNav {...props}>
            {props.lista.map((chave, valor)=>{
              if(chave.tipo === 'link'){
                return  <Grid row >
                          <span style={{
                          marginLeft: '8px',
                          marginRight: '8px'
                        }}>{valor >= 1 ? <Icon type="next" /> : ""}</span>
                          <Links
                            to={chave.link}
                            type="breadcumbs"
                            bold={chave.ativado ? true : false}
                            underline={chave.ativado ? false : true}
                            color='#292B30'
                            label={chave.nome}
                          />
                        </Grid>
              }
              else{
                return  <span style={{verticalAlign: 'middle', marginTop: '10px'}}>
                          <Icon type={chave.icone} />
                        </span>
              }
            })}
         </StyledNav>
}


export default Breadcumbs
