import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`

`

function Container(props){
  return <StyledContainer {...props}>
            {props.children}
         </StyledContainer>
}

export default Container
