import React from 'react'
import styled from 'styled-components'

const DivContainer = styled.div`
  display: flex;
  flex-direction: ${props=>props.row ? `row` : `column`};
  flex-wrap: ${props=>props.wrap ? `wrap`: `no-wrap`};
  justify-content: ${props=>props.spacing ? `${props.spacing}` : `flex-start`}
  
`
function Grid(props){
  return <DivContainer {...props}>
          {props.children}
         </DivContainer>
}

export default Grid
