import React from 'react'
import styled from 'styled-components'

const StyledLabel = styled.label`

font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;

color: #292B30;
`

function Label(props){
  return <StyledLabel >
            {props.children}
         </StyledLabel>
}

export default Label
