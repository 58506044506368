import React from 'react'
import styled from 'styled-components'

const Txt = styled.p`
font-family: Raleway;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 28px;
`

function Text(props){
  return <Txt {...props}>{props.children}</Txt>
}

export default Text
