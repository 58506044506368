import React from 'react'
import styled from 'styled-components'
import alertRed from './icons/alert-red.svg'
import alertRedOutline from './icons/alert-red-outline.svg'
import successGreen from './icons/success-green.svg'
import dashboard from './icons/dashboard.svg'
import cadastro from './icons/cadastro.svg'
import pesquisas from './icons/pesquisas.svg'
import resultados from './icons/resultados.svg'
import certificados from './icons/certificados.svg'
import rankings from './icons/rankings.svg'
import colaboradores from './icons/colaboradores.svg'
import biblioteca from './icons/biblioteca.svg'
import pesquisar from './icons/pesquisar.svg'
import alertWhite from './icons/alert-white.svg'
import iconePlay from './icons/icone-play.svg'
import check from './icons/check.svg'
import gptw from './icons/gptw.svg'
import upgrade from './icons/upgrade.svg'
import alertOrange from './icons/alert-orange.svg'
import uploadFile from './icons/upload.svg'
import downloadFile from './icons/download.svg'
import connect from './icons/connect.svg'
import circle from './icons/circle.svg'
import configuracoes from './icons/configuracoes.svg'
import exit from './icons/exit.svg'
import sms from './icons/sms.svg'
import checkYes from './icons/check-yes.svg'
import checkNo from './icons/check-no.svg'
import radioNo from './icons/radio-no.svg'
import radioYes from './icons/radio-yes.svg'
import arrowDown from './icons/arrow-down.svg'
import arrowUp from './icons/arrow-up.svg'
import next from './icons/next.svg'
import alertSearch from './icons/alert-search.svg'
import alertCheck from './icons/alert-check.svg'
import alertGray from './icons/alert-gray.svg'
import plusRed from './icons/plus-red.svg'
import x from './icons/x.svg'
import collabsBlue from './icons/collabs-blue.svg'
import collabsGreen from './icons/collabs-green.svg'
import collabsBluegreen from './icons/collabs-bluegreen.svg'
import collabsOrange from './icons/collabs-orange.svg'
import tableCheck from './icons/table-check.svg'
import tableUncheck from './icons/table-uncheck.svg'
import tableEdit from './icons/table-edit.svg'
import tableTrash from './icons/table-trash.svg'
import closeGray from './icons/close-gray.svg'
import closeGrayCircle from './icons/close-gray-circle.svg'
import upgradePng from './icons/upgrade.png'
import checkGreen from './icons/green-check-100.svg'
import pana from './icons/pana.svg'
import data from './icons/data.svg'
import chain from './icons/chain.svg'
import checkLow from './icons/check-low.svg'
import closeRed from './icons/close-red.svg'
import bill from './icons/bill.svg'

const StyledImg = styled.img`
  vertical-align: middle;

  ${props=>{
    if(props.tituloBar){return `width: 60px; height: 60px;`}
    else if(props.footerBar){return `width: 192px; height: 249px;`}
    else if(props.width && props.height){return `width: ${props.width}; height: ${props.height};`}
    else { return `width: 16px; height: 16px;` }
  }}

`

function Icon(props){
  switch (props.type) {
    case "alert-red": return (<StyledImg src={alertRed}  />); break;
    case "alert-red-outline": return (<StyledImg src={alertRedOutline} width="16px" height="16px"  />); break;
    case "upload-file": return (<StyledImg src={uploadFile} width="24px" height="24px"  />); break;
    case "download-file": return (<StyledImg src={downloadFile} width="24px" height="24px"  />); break;
    case "success-green": return (<StyledImg src={successGreen} />); break;
    case "dashboard": return (<StyledImg src={dashboard} />); break;
    case "cadastro": return (<StyledImg src={cadastro} />); break;
    case "pesquisas": return (<StyledImg src={pesquisas} />); break;
    case "resultados": return (<StyledImg src={resultados} />); break;
    case "certificados": return (<StyledImg src={certificados} />); break;
    case "rankings": return (<StyledImg src={rankings} />); break;
    case "colaboradores": return (<StyledImg src={colaboradores} />); break;
    case "biblioteca": return (<StyledImg src={biblioteca} />); break;
    case "pesquisar": return (<StyledImg src={pesquisar} width="18px" height="18px" />); break;
    case "alert-white": return (<StyledImg src={alertWhite} />); break;
    case "icone-play": return(<StyledImg src={iconePlay}  width="40px" height="40px"  />); break;
    case "alert-orange": return(<StyledImg src={alertOrange} />); break;
    case "alert-gray": return(<StyledImg src={alertGray} />); break;
    case "check-yes": return(<StyledImg src={checkYes} />); break;
    case "check-no": return(<StyledImg src={checkNo} />); break;
    case "radio-yes": return(<StyledImg src={radioYes} />); break;
    case "radio-no": return(<StyledImg src={radioNo} />); break;
    case "check": return(<StyledImg src={check} />); break;
    case "circle": return(<StyledImg src={circle} width={props.width} height={props.height}  />); break;
    case "configuracoes": return(<StyledImg src={configuracoes} width={props.width} height={props.height}  />); break;
    case "exit": return(<StyledImg src={exit} width={props.width} height={props.height}  />); break;
    case "sms": return(<StyledImg src={sms} width={props.width} height={props.height} />); break;
    case "gptw": return(<StyledImg src={gptw} tituloBar={props.tituloBar}  />); break;
    case "connect": return(<StyledImg src={connect} width="342px" height="48px"  />); break;
    case "arrow-up": return(<StyledImg src={arrowUp} width="20px" height="20px"  />); break;
    case "arrow-down": return(<StyledImg src={arrowDown} width="20px" height="20px"  />); break;
    case "next": return(<StyledImg src={next} width="24px" height="24px"  />); break;
    case "alert-search": return(<StyledImg src={alertSearch} width="30px" height="30px"  />); break;
    case "alert-check": return(<StyledImg src={alertCheck} width="30px" height="30px"  />); break;
    case "upgrade": return(<StyledImg src={upgradePng} footerBar={props.footerBar}  />); break;
    case "plus-red": return(<StyledImg src={plusRed} width="36px" height="36px" />); break;
    case "x": return(<StyledImg src={x} width="16px" height="16px" />); break;
    case "": return(<StyledImg src={x} width="16px" height="16px" />); break;
    case "collabs-blue": return(<StyledImg src={collabsBlue} width="40px" height="40px" />); break;
    case "collabs-green": return(<StyledImg src={collabsGreen} width="40px" height="40px" />); break;
    case "collabs-bluegreen": return(<StyledImg src={collabsBluegreen} width="40px" height="40px" />); break;
    case "collabs-orange": return(<StyledImg src={collabsOrange} width="40px" height="40px" />); break;
    case "table-check": return(<StyledImg src={tableCheck} width="20px" height="20px" />); break;
    case "table-unchecked": return(<StyledImg src={tableUncheck} width="20px" height="20px" />); break;
    case "table-edit": return(<StyledImg src={tableEdit} width="20px" height="20px" />); break;
    case "table-trash": return(<StyledImg src={tableTrash} width="20px" height="20px" />); break;
    case "close-gray": return(<StyledImg src={closeGray} width="23px" height="23px" />); break;
    case "close-gray-circle": return(<StyledImg src={closeGrayCircle} width="23px" height="23px" />); break;
    case "close-red": return(<StyledImg src={closeRed} width="16px" height="16px" />); break;
    case "check-green": return(<StyledImg src={checkGreen} width={props.width} height={props.height} />); break;
    case "pana": return(<StyledImg src={pana} width="240.64px" height="235px" />); break;
    case "date": return(<StyledImg src={data} width="28px" height="28px" />); break;
    case "chain": return(<StyledImg src={chain} width="24px" height="24px" />); break;
    case "check-low": return(<StyledImg src={checkLow} width="16px" height="16px" />);break;
    case "bill": return(<StyledImg src={bill} width="28px" height="28px" />); break;
    default:
      return <StyledImg src={props.src} />
  }
}

export default Icon
