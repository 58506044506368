import React from 'react';
import styled from 'styled-components';
import { blueLibrary, greenLibrary, Grid, Icon, Links, purpleLibrary, redLibrary } from '../../..';


const ContainerStyled = styled.div`
    ${props=>{
        switch(props.icon){
            case 'red-library':  return(`background-image: url('${redLibrary}');`); break;
            case 'blue-library': return(`background-image: url('${blueLibrary}');`);  break;
            case 'green-library': return(`background-image: url('${greenLibrary}');`); break;  
            case 'purple-library': return(`background-image: url('${purpleLibrary}');`);  break;
            default: return(`background-image: url('${redLibrary}');`);  break;
        }
    }}
    background-repeat: no-repeat;
    width: 548px;
    height: 148px;
    border-radius: 12px;
    position: relative;

    margin-right: 16px;
    margin-bottom: 16px;
`

const Tag = styled.div`
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    color: ${props=>props.color ? props.color : '#fff'};
    background-color: ${props=>props.background ? props.background : '#000'};
    width: 133px;
    height: 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

   

`

const Title = styled.h1`
font-family: Raleway;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 34px;

color: #FFFFFF;

margin-top: 8px;
`


function LibraryContainer(props){
    
    return  <ContainerStyled icon={props.icon}>
                <Grid colum style={{
                    position: 'absolute',
                    top: '32px',
                    left: '32px'
                }}>
                    <Tag color={props.color} background={props.background}>
                        {props.tag}
                    </Tag>
                    <Title>{props.title}</Title>
                    <Links
                        type="libraryLink"  
                        to={props.link.url}
                        label={props.link.text}
                    />
                </Grid>
            </ContainerStyled>
}

export default LibraryContainer