import React, {useEffect} from 'react'
import styled from 'styled-components'
import { Links, Icon, Button } from '../../../style'
import { useLocation } from 'react-router-dom'
import getUrlParams from '../../../services/getUrlParams'

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  width: 263px;

  margin-right: 32px;

  background-color: #fff;

  position: fixed;
  bottom: 0;
  left: 0;

  z-index: 1;
  height: 100%;

`

const ContainerPrincipal = styled.div`
  display: flex;
  flex-direction: column;

`

const ContainerTitulo = styled.div`
  margin: 0;
  margin-top: 40px;
  margin-left: 32px;
  margin-bottom: 40px;
`

const ContainerFooter = styled.div`
  margin: 0;
  margin-top: 480px;
  margin-left: 24px;
  margin-bottom: 32px;

  position: relative;
`

const ContainerCenter = styled.div`

`

const FooterText = styled.p`
font-family: Raleway;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 22px;
text-align: center;

color: #292B30;

position: absolute;
bottom: 75px;
left: 21px;

width: 150px;
height: 42px;
`

function NavBarVertical(props){
  let  location = useLocation()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const cr = urlParams.get("cr")
  const st = urlParams.get("st")
  const token = btoa(cr);
  console.log(token)

  const menus = [
    {'icone': 'colaboradores', 'nome': 'Faturas',  'link': `/bills?cr=${cr}&st=${st}&token=${token}`,  'hover': boldLocation('/bills')},
    {'icone': 'cadastro', 'nome': 'Cadastro',  'link': `/registration/profile?cr=${cr}&st=${st}&token=${token}`,  'hover': boldLocation('/registration')}
  ]


  function boldLocation(path){
    if(location.pathname.includes(path)){
      return true
    }
    else{
      return false
    }
  }


  useEffect(()=>{
    console.error('location ', menus)
    
  })

 
  return  <StyledNav >
            <ContainerCenter>
              <ContainerTitulo>
                <Icon type="gptw" tituloBar={true} />
              </ContainerTitulo>
              <ContainerPrincipal>
                {menus.map((chave, valor)=>{
                  return  <Links
                            to={chave.link}
                            type="menuVertical"
                            color="#5C6066"
                            label={chave.nome}
                            icone={chave.icone}
                            inicial={valor === 0 ? true : false}
                            final={valor === (menus.length - 1 ) ? true : false}
                            bolder={chave.hover}
                          />
                })}
              </ContainerPrincipal>
              
              {/* <ContainerFooter>
                <Icon type="upgrade" footerBar={true} />
                <FooterText>Estamos aqui para te ajudar</FooterText>
                <Button
                  label="Falar com o consultor"
                  width="162px"
                  height="48px"                  
                  style={{
                    position: 'absolute',
                    bottom: '15px',
                    left: '15px'                    
                  }}
                />
              </ContainerFooter> */}
            </ContainerCenter>
          </StyledNav>
}

export default NavBarVertical
