import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";

import logoSidebar from "../../../assets/images/logo-sidebar.svg";
import logoJungleSideBar from "../../../assets/images/jungle_brand_blue.png";
import logoJungleSideBarPequena from "../../../assets/images/jungle_brand_blue-pequena.png";
import navbarIcon from "../../../assets/images/navbar-icon.svg";
import iconSettings from "../../../assets/images/icon-settings.svg";
import iconLogout from "../../../assets/images/icon-logout.svg";
import iconsMessage from "../../../assets/images/icon-sms.svg";
import language from "../../../assets/images/button-language.svg";
import PopUp from "../../../style/modal/popup/index";
import iconSelected from "../../../assets/images/icon-arrow-down.svg";

import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  background,
  Button,
  Image,
} from "@chakra-ui/react";

import {
  FiHome,
  FiMenu,
  FiBook,
  FiCompass,
  FiSettings,
  FiUsers,
} from "react-icons/fi";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { researchApi } from "../../../services/api-research";
import { CustomContext } from "../../../context/CustomContext";
import { getCookie, removeCookie } from "../../common/cookieManager";
import { useTranslation } from "react-i18next";
import Translator from "../../components/i18n/Translator";

export default function SidebarWithHeader({ children, path }) {
  const {
    currentCompanyLogo,
    setCurrentCompanyLogo,
    setSystemLanguage,
    systemLanguage,
    userPermissions
  } = useContext(CustomContext);
  const { i18n } = useTranslation();
  const location = useLocation();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const cr = urlParams.get("cr");

  const LinkItems = [
    {
      name: <Translator path={"company.sidebar"} />,
      icon: FiBook,
      path: `/registration?cr=${cr}`,
      ref: "registration",
      disabled: !userPermissions.includes('COMPANY_EDIT')
    },
    {
      name: <Translator path={"survey.title"} />,
      icon: FiCompass,
      path: `/researches?cr=${cr}`,
      ref: "researches",
      disabled: !userPermissions.includes('SURVEY_VIEW')
    },
    {
      name: <Translator path={"myCertifications.title"} />,
      icon: FiSettings,
      path: `/certificates?cr=${cr}`,
      ref: "certificates",
      disabled: !userPermissions.includes('CERTIFICATIONS_VIEW')
    },
    {
      name: <Translator path={"results.title"} />,
      icon: FiCompass,
      path: `/results?cr=${cr}`,
      ref: "results",
      disabled: !userPermissions.includes('RESULTS_VIEW')
    },
    {
      name: <Translator path={"employees.title"} />,
      icon: FiUsers,
      path: `/collaborators?cr=${cr}`,
      ref: "collaborators",
      disabled: !userPermissions.includes('EMPLOYEE_VIEW')
    },
  ];

  const { onOpen, onClose } = useDisclosure();

  const companyCookie = JSON.parse(
    Buffer.from(getCookie("company"), "base64").toString("ascii")
  );
  const userCookieEmail = getCookie("user_email");

  useEffect(() => {
    async function getLogo() {
      try {
        const response = await researchApi.get(
          `/companies/${companyCookie.id}/logo`,
          {
            responseType: "blob",
          }
        );
        const blob = new Blob([response.data], { type: "image/png" });
        const logoObject = URL.createObjectURL(blob);

        setCurrentCompanyLogo(logoObject);
      } catch (error) {
        console.error(
          `Erro ao recuperar a logo da empresa: ${companyCookie.id}`,
          error
        );
      }
    }

    if (!!!currentCompanyLogo) {
      getLogo();
    }
  }, [
    companyCookie.id,
    companyCookie.logoKey,
    currentCompanyLogo,
    setCurrentCompanyLogo,
  ]);

  const handleChangeLanguage = async (e) => {
    let newLanguage;
    if (!!e.target.value) {
      newLanguage = e.target.value;
    } else {
      newLanguage = "pt-BR";
    }

    const userId = getCookie("user_id");

    try {
      await researchApi.put(`/users/${userId}`, {
        language: newLanguage,
        email: userCookieEmail,
      });
    } catch (error) {
      console.error("Error ao atualizar info do usuário", error);
    }

    setSystemLanguage(newLanguage);
    localStorage.setItem("i18nextLng", newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <Box minH="100vh" p={0}>
      <Box
        transition="3s ease"
        bg={useColorModeValue("white", "gray.900")}
        w="190px"
        pos="fixed"
        h="full"
      >
        <Flex
          // h="120px"
          h="auto"
          className="link-active"
        >
          <img
            // src={logoSidebar}
            // style={{ width: "60px", marginTop: "10px" }}

            src={logoJungleSideBar}
            style={{ width: "70%", marginTop: "40px" }}
            alt="sidebar logo"
          />

          <CloseButton
            display={{ base: "flex", md: "none" }}
            onClick={onClose}
          />
        </Flex>

        {LinkItems.map((link) => (
          <NavItem
            key={link.ref}
            icon={link.icon}
            path={link.path}
            disabled={link.disabled}
          >
            {link.name}

            {location.pathname.includes(link.ref) && (
              <Box w="4px" h="32px" bg="#013280" ml="auto" />
            )}
          </NavItem>
        ))}
      </Box>

      <MobileNav
        onOpen={onOpen}
        companyCookie={companyCookie}
        userCookieEmail={userCookieEmail}
        currentLogo={currentCompanyLogo}
        handleChangeLanguage={handleChangeLanguage}
        systemLanguage={systemLanguage}
      />
      <Box ml="203px" py={4}>
        {children}
      </Box>
      {/* {!localStorage.getItem('connect-notificated') &&
        <PopUp />
      } */}
    </Box>
  );
}

const NavItem = ({ icon, children, path, disabled, ...rest }) => {
  return (
    <Link
      className="link-active"
      to={path}
      style={
        disabled
          ? { pointerEvents: "none", opacity: "0.4" }
          : { textDecoration: "none", height: "46px" }
      }
    >
      <Flex
        align="center"
        fontSize="14px"
        role="group"
        h="46px"
        cursor="pointer"
        _hover={{
          color: "#292B30",
          fontWeight: "medium",
        }}
        _activeLink={{
          color: "#292B30",
          fontWeight: "medium",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            h="100%"
            display="flex"
            alignItems="center"
            mr="4"
            fontSize="18"
            _groupHover={{
              color: "#013280",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({
  onOpen,
  companyCookie,
  userCookieEmail,
  currentLogo,
  handleChangeLanguage,
  systemLanguage,
  ...rest
}) => {
  const handleLogout = () => {
    removeCookie("user_id");
    removeCookie("credential");
    removeCookie("company");
    removeCookie("first_access");
    removeCookie("user_email");

    window.location.replace("/login");
  };

  return (
    <Flex
      ml={{ base: 0, md: 20 }}
      px={{ base: 4, md: 4 }}
      height="64px"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Logo
      </Text>

      <HStack w="513px" p={0}>
        <Flex alignItems={"center"} w="100%" justifyContent="space-between">
          {/* <Menu>
            <MenuButton
              w="100%"
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            > */}

          <Box
            w="100%"
            py={2}
            transition="all 0.3s"
            _focus={{ boxShadow: "none" }}
          >
            <HStack p="0" display="flex" justifyContent={"space-between"}>
              {/* <Menu>
                <MenuButton>
                  <img
                    src={language}
                    alt="icon language"
                    // style={{ marginRight: "15px" }}
                  />
                  <MenuList>
                    <MenuItem
                      py="10px"
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="16px"
                    >
                      pt-BR
                    </MenuItem>
                    <MenuItem
                      py="10px"
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="16px"
                    >
                      en-US
                    </MenuItem>
                    <MenuItem
                      py="10px"
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="16px"
                    >
                      es
                    </MenuItem>
                  </MenuList>
                </MenuButton>
              </Menu> */}

              <Select
                fontSize="14px"
                fontWeight="400"
                lineHeight={"16.44px"}
                icon={<img src={iconSelected} />}
                placeholder="pt-BR"
                borderRadius="10px"
                background={"#F1F5FA"}
                boxSizing="border-box"
                defaultValue="pt-BR"
                width="90px"
                height="36px"
                position={"absolute"}
                border="none"
                onChange={(e) => handleChangeLanguage(e)}
                _focus={{
                  border: "none",
                }}
                value={systemLanguage}
              >
                <option value="en-US">en-US</option>
                <option value="es">es</option>
              </Select>

              <img
                src={iconsMessage}
                alt="icon message"
                // style={{ marginRight: "15px" }}
              />

              <Box
                // display={{ base: "none", md: "flex" }}
                display="flex"
                w="240px"
                justifyContent="space-between"
              >
                <VStack
                  display={{ base: "none", md: "flex" }}
                  // alignItems="flex-end"
                  spacing="1px"
                >
                  <Text fontSize="13px" color="#013280" fontWeight="600">
                    {/* {companyName} */}
                    {companyCookie.name}
                  </Text>
                  <Text fontSize="12px" color="gray.600">
                    {userCookieEmail}
                  </Text>
                </VStack>
                {/* <img src={navbarIcon} alt="company icon" /> */}
                {
                  <Flex
                    // position="absolute"
                    w="40px"
                    h="40px"
                    borderColor="#B8CAE8"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {companyCookie.logoKey ? (
                      <Image
                        src={currentLogo}
                        alt="icon"
                        // position="absolute"
                        // w="40px"
                        // h="40px"
                      />
                    ) : (
                      <Image
                        src={navbarIcon}
                        alt="icon"
                        // position="absolute"
                        // w="40px"
                        // h="40px"
                      />
                    )}
                  </Flex>
                }
              </Box>
              <Box
                // display={{ base: "none", md: "flex" }}
                justifySelf={"flex-end"}
                display="flex"
                w="78px"
                h="100%"
                justifyContent="space-between"
              >
                {/* <img src={iconSettings} alt="icon settings" /> */}
                <Button
                  bg="transparent"
                  variant="link"
                  border="none"
                  outline="none"
                  _focus={{
                    background: "transparent",
                  }}
                  onClick={handleLogout}
                >
                  <Image src={iconLogout} />
                </Button>
                {/* <img src={iconLogout} alt="icon logout" /> */}
              </Box>
            </HStack>
          </Box>

          {/* </MenuButton>

            <MenuList
              ml="300px"
              color={"#292B30;"}
              fontSize="14px"
              lineHeight="16px"
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
              borderRadius="12px"
            >
              <MenuItem
                py="10px"
                fontWeight="500"
                fontSize="14px"
                lineHeight="16px"
              >
                Configurações do cadastro
              </MenuItem>
              <MenuItem
                py="10px"
                fontWeight="500"
                fontSize="14px"
                lineHeight="16px"
              >
                Gerenciar responsáveis
              </MenuItem>
              <MenuItem
                py="10px"
                fontWeight="500"
                fontSize="14px"
                lineHeight="16px"
              >
                Termos de compromisso
              </MenuItem>
              <MenuItem
                py="10px"
                fontWeight="500"
                fontSize="14px"
                lineHeight="16px"
              >
                Regras de participação
              </MenuItem>
              <MenuItem
                py="10px"
                fontWeight="500"
                fontSize="14px"
                lineHeight="16px"
              >
                Central de ajuda
              </MenuItem>
            </MenuList>
          </Menu> */}
        </Flex>
      </HStack>
    </Flex>
  );
};
